import React, {useContext, useEffect, useState} from "react";
import {Checkbox, Input, Select} from "semantic-ui-react";
import Preloader from "./Preloader";
import {AppContext} from "../App";
import {DoRequest, GET_DIGESTS, GET_POSTS, RTK_START_URI} from "../controller/DoRequest";
import swal from "sweetalert";
import DeleteCookie from "../controller/cookie/DeleteCookie";

const Filters = ({setMainPosts}) => {
  let width = window.innerWidth;
  const {appContext, setAppContext} = useContext(AppContext);
  const [disabled, setDisabled] = useState(false)
  const [toggleIfSizeChange, setToggleIfSizeChange] = useState(width > 768);

  const [mediaCarrier, setMediaCarrier] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [city, setCity] = useState()
  const [region, setRegion] = useState()

  const [mediaCarrierSelected, setMediaCarrierSelected] = useState(appContext.selected_main_menu.media_carriers)
  const [monthSelected] = useState(appContext.selected_main_menu.month)
  const [yearSelected] = useState(appContext.selected_main_menu.year)
  const [citySelected] = useState(appContext.selected_main_menu.city)
  const [regionSelected, setRegionSelected] = useState(appContext.selected_main_menu.region)
  const [ponp] = useState(appContext.selected_main_menu.ponp)
  const [anop] = useState(appContext.selected_main_menu.anop)

  useEffect( () => {
    function getMainMenu() {
      let params = {
        type: 'post',
        path: RTK_START_URI + GET_DIGESTS
      }
      DoRequest(params)
        .then(response => {
          setAppContext(c => Object.assign(c, {main_menu: response.data}));
          constructMenuAttrs();
        })
        .catch((err) => {
          swal("Произошла ошибка", "Проблема при связи с сервером", "error").then(() => {
            console.log(err.toString());
            DeleteCookie("token");
          });
        });
    }

    function constructMenuAttrs() {
      let mediaCarriers = [];
      let months = [];
      let years = [];
      let cities = [];
      let regions = [];

      let selectedMediaCarriers = [];
      let selectedRegions = [];

      for (const el in appContext.main_menu.media_carrier) {
        mediaCarriers = [...mediaCarriers, {
          key: el, value: el, text: el
        }];
        selectedMediaCarriers = [...selectedMediaCarriers, el];
      }
      for (const el in appContext.main_menu.month) {
        months = [...months, {
          key: el, value: el, text: el
        }]
      }
      for (const el in appContext.main_menu.year) {
        years = [...years, {
          key: el, value: el, text: el
        }]
      }
      for (const el in appContext.main_menu.city) {
        cities = [...cities, {
          key: el, value: el, text: el
        }]
      }
      for (const el in appContext.main_menu.region) {
        regions = [...regions, {
          key: el, value: el, text: el
        }]
        selectedRegions = [...selectedRegions, el];
      }

      setMediaCarrier(mediaCarriers);
      setMediaCarrierSelected(selectedMediaCarriers)

      setMonth(months);
      setYear(years);
      setCity(cities);

      setRegion(regions);
      setRegionSelected(selectedRegions)
    }

    if (appContext !== undefined && appContext.main_menu === undefined) {
      getMainMenu();
    } else if (appContext.main_menu) {
      constructMenuAttrs();
    }
  }, [appContext, setAppContext]);

  const handleGetPosts = () => {
    setDisabled(true);
    let params = {
      data: appContext.selected_main_menu,
      type: 'post',
      path: RTK_START_URI + GET_POSTS
    }
    DoRequest(params)
        .then(response => {
          setAppContext(c => Object.assign(c, {main_posts: response.data}));
          setMainPosts(response.data);
          if(width <= 768) {
            setToggleIfSizeChange(false);
          }
          setDisabled(false);
        })
        .catch((err) => {
          setDisabled(false);
          swal("Произошла ошибка", "Проблема при связи с сервером", "error").then(() => {
            console.log(err.toString());
          });
        });
  }

  const handleOnChange = (e, data) => {
    let smm = appContext.selected_main_menu;
    smm[data.id] = data.value;
    setAppContext(c => Object.assign(c, {"selected_main_menu": smm}));
  }

  const showHide = () => {
    setToggleIfSizeChange(!toggleIfSizeChange);
    // if (toggleIfSizeChange) {
    //   setToggleIfSizeChange(false)
    // } else {
    //   setToggleIfSizeChange(true)
    // }
  }

  return (
    <>
      {width <= 768? <div className="row p-4 d-flex d-sm-none">
        <p onClick={() => showHide()} className="btn btn-sm btn-outline-secondary">{toggleIfSizeChange? "Убрать" : "Показать"} фильтры</p>
      </div> : <></>}
        {mediaCarrierSelected? toggleIfSizeChange?
          <div className={disabled? "disable-component" : ""}>
            <div className="row p-4">
              <div className="col-md-4 col-sm-6 p-2">
                <label>Медиа носитель</label>
                <Select
                  id="media_carriers"
                  placeholder='Медиа носитель'
                  fluid
                  multiple
                  search
                  selection
                  clearable
                  defaultValue={mediaCarrierSelected}
                  options={mediaCarrier}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-2 col-sm-6 p-2">
                <label>Месяц</label>
                <Select
                  id="monthes"
                  placeholder='Месяц'
                  fluid
                  selection
                  defaultValue={monthSelected}
                  options={month}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-2 col-sm-6 p-2">
                <label>Год</label>
                <Select
                  id="year"
                  placeholder='Год'
                  fluid
                  selection
                  defaultValue={yearSelected}
                  options={year}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-2 col-sm-6 p-2">
                <label>Город</label>
                <Select
                  id="city"
                  placeholder='Город'
                  fluid
                  multiple
                  search
                  selection
                  clearable
                  defaultValue={citySelected}
                  options={city}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-2 col-sm-6 p-2">
                <label>Площадка: Сайт</label>
                <Input value="" fluid focus placeholder='Площадка: Сайт'/>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 p-2">
                <label>Регион</label>
                <Select
                  id={region}
                  placeholder='Регион'
                  fluid
                  multiple
                  search
                  selection
                  clearable
                  defaultValue={regionSelected}
                  options={region}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-6 col-sm-6 p-2">
                <label>Формат Услуги</label>
                <Input fluid focus placeholder='Формат Услуги'/>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-3 col-sm-6 p-2 text-center">
                <div className="row">
                  <label className="col-6">Тендер</label>
                  <Checkbox className="col-6" slider defaultChecked={true}/>
                </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6 p-2 text-center">
                <div className="row">
                  <label className="col-6">Вне тендера</label>
                  <Checkbox className="col-6" slider defaultChecked={true}/>
                </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6 p-2 text-center">
                <div className="row">
                  <label className="col-6">Планируемое заполнено</label>
                  <Checkbox className="col-6" slider defaultChecked={ponp[0] === 'ponp'}/>
                </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6 p-2 text-center">
                <div className="row">
                  <label className="col-6">Согласованное заполнено</label>
                  <Checkbox className="col-6" slider defaultChecked={anop[0] === 'anop'}/>
                </div>
              </div>
            </div>
            <div className="row col-4">
              <p onClick={() => handleGetPosts()} className="btn btn-primary btn-block">
                Применить {disabled? <Preloader type="button" /> : <></>}
              </p>
            </div>
          </div> : <></> : <></>}
    </>
  );
}

export default Filters;
