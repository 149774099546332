import React from "react";
import {For} from "react-loops";
import {Accordion} from 'react-bootstrap';
import MainPostMobile from './MainPostMobile';
import MainPost from "./MainPost";

const MainPosts = ({mainPosts}) => {

  return (<>
    {mainPosts?
      <Accordion flush className="p-4">
        <For of={Object.keys(mainPosts)} as={(id, {index}) =>
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              {index + 1} {mainPosts[id]["type"]} {mainPosts[id]["media_carrier"]} {mainPosts[id]["month"]} {mainPosts[id]["region"]} {mainPosts[id]["city"]}
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-sm-none">
                <MainPostMobile postId={id} post={mainPosts[id]} />
              </div>
              <div className="d-none d-sm-flex">
                <MainPost post={mainPosts[id]} />
              </div>
            </Accordion.Body>
          </Accordion.Item>}
        />
      </Accordion>
      : <></>}
  </>)
}

export default MainPosts;
