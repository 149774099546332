import axios from "axios";
import GetCookie from "./cookie/GetCookie";

export const TOKEN_START_URI = "/jwt-auth/v1"
export const RTK_START_URI = "/rtk/v1"

export const GET_TOKEN = "/token"
export const TOKEN_VALIDATE = "/token/validate"
export const GET_DIGESTS = "/get-digests"
export const GET_POSTS = "/get-posts"

export const DoRequest = (params) => {

    const SERVER_HOST = "https://eff-media.na4u.ru/?rest_route="
    let MAST_HAVE_HEADERS = {
        'Content-Type': 'application/json;charset=utf-8'
    }
    if (params.path !== TOKEN_START_URI + GET_TOKEN) {
        MAST_HAVE_HEADERS['Authorization'] = 'Bearer ' + GetCookie("token");
    }

    let concatHeaders = params.headers === undefined?
        MAST_HAVE_HEADERS :
        Object.assign(params.headers, MAST_HAVE_HEADERS);

    let conf = {
        headers: concatHeaders
    };

    if (params.type === 'get') {
        let data = params.data === undefined? conf : Object.assign(conf, {params: params.data});
        return axios.get(SERVER_HOST + params.path, data);
    }

    if (params.type === 'post') {
        let data = params.data === undefined? {} : params.data;
        return axios.post(SERVER_HOST + params.path, data, conf);
    }
}
