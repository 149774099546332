import React, {useEffect, useState} from "react";
import MainPostModalSaveField from "./MainPostModalSaveField";

const MainPostMobile = ({post}) => {

  const [modalShow, setModalShow] = useState(false);
  const [fieldName, setFieldName] = useState();
  const [thisPost, setThisPost] = useState(post);
  let [fieldContent, setFieldContent] = useState();

  useEffect(() => {
    console.log(fieldContent)
  }, [fieldContent])

  const handleUpdateField = (event, fName) => {
    setFieldContent(event.target.textContent);
    setFieldName(fName);
    setModalShow(true);
    setThisPost({...thisPost, fName: fieldContent});
  }

  return (
    <>
      <div className="row">
        <b className="col-4 text-truncate">Формат услуги</b>
        <p onDoubleClick={event => handleUpdateField(event, "service_format")} className="col-8">{post["service_format"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Площадка: Сайт</b>
        <p onDoubleClick={event => handleUpdateField(event, "site_website")} className="col-8">{post["site_website"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Ориентировочные темы</b>
        <p onDoubleClick={event => handleUpdateField(event, "oriented_themes")} className="col-8">{post["oriented_themes"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Ориентировочные даты</b>
        <p onDoubleClick={event => handleUpdateField(event, "oriented_dates")} className="col-8">{post["oriented_dates"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Планируемое кол-во размещений</b>
        <p onDoubleClick={event => handleUpdateField(event, "ponp")} className="col-8">{post["ponp"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Планируемое кол-во размещений</b>
        <p onDoubleClick={event => handleUpdateField(event, "anop")} className="col-8">{post["anop"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Коэффициент за услуги монтажа из исходников Заказчика (без съемки сюжета)</b>
        <p onDoubleClick={event => handleUpdateField(event, "installation_service_rate")} className="col-8">{post["installation_service_rate"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Коэффициент за выезд съемочной бригады в дополнительное место съемки</b>
        <p onDoubleClick={event => handleUpdateField(event, "film_crew_rate")} className="col-8">{post["film_crew_rate"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Коэффициент за съемку в выходной день</b>
        <p onDoubleClick={event => handleUpdateField(event, "weekend_shooting_rate")} className="col-8">{post["weekend_shooting_rate"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Коэффициент за позиционирование</b>
        <p onDoubleClick={event => handleUpdateField(event, "cfp")} className="col-8">{post["cfp"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Стоимость за ед. без учета НДС 20%</b>
        <p onDoubleClick={event => handleUpdateField(event, "cost_without_irf")} className="col-8">{post["cost_without_irf"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Сезонный коэффициент</b>
        <p onDoubleClick={event => handleUpdateField(event, "season_coefficient")} className="col-8">{post["season_coefficient"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Стоимость без НДС с коэф.</b>
        <p onDoubleClick={event => handleUpdateField(event, "cost_without_nds_with_coefficients")} className="col-8">{post["cost_without_nds_with_coefficients"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Планируемая стоимость с НДС и коэф.</b>
        <p onDoubleClick={event => handleUpdateField(event, "planed_cost_without_nds_with_coefficients") } className="col-8">{post["planed_cost_without_nds_with_coefficients"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Согласованная стоимость с НДС и коэф.</b>
        <p onDoubleClick={event => handleUpdateField(event, "accepted_cost_without_nds_with_coefficients") } className="col-8">{post["accepted_cost_without_nds_with_coefficients"]}</p>
      </div>
      <div className="row">
        <b className="col-4 text-truncate">Примечания</b>
        <p onDoubleClick={event => handleUpdateField(event, "service_format")} className="col-8">{post["service_format"]}</p>
      </div>
      <MainPostModalSaveField
        show={modalShow}
        onHide={() => setModalShow(false)}
        fieldName={fieldName}
        fieldContent={fieldContent}
        setFieldContent={setFieldContent}
      />
    </>
  )
}

export default MainPostMobile;
