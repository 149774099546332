import React, {useState} from "react";
import MainPage from "./pages/MainPage";
import {BrowserRouter} from "react-router-dom";

export const AppContext = React.createContext({});

const App = () => {
  const [appContext, setAppContext] = useState({selected_main_menu: {
      "types": [],
      "ponp": [
        "ponp"
      ],
      "anop": [
        "anop"
      ],
      "media_carriers": [
        "Интернет",
        "Пресса",
        "Радио",
        "ТВ"
      ],
      "monthes": new Date().toLocaleString('ru', { month: 'long' }).substring(0,1).toUpperCase()
        + new Date().toLocaleString('ru', { month: 'long' }).substring(1),
      "year": new Date().getFullYear().toString(),
      "region": [
        "ЕФ",
        "ПФ",
        "ТКФ",
        "ХМАО",
        "ЧФ",
        "ЯНФ"
      ],
      "city": [],
      "site_website": "",
      "service_format": ""
    }});
  const value = {appContext, setAppContext};

  return (
    <AppContext.Provider value={value}>
        <BrowserRouter>
            <MainPage />
        </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
