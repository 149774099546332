import React, {useState} from "react";
import Meta from '../components/Meta';
import {DoRequest, GET_TOKEN, TOKEN_START_URI} from "../controller/DoRequest";
import SetCookie from "../controller/cookie/SetCookie";
import Preloader from "../components/Preloader";
import swal from "sweetalert";
import PropTypes from 'prop-types';
import DeleteCookie from "../controller/cookie/DeleteCookie";

export default function Login({setToken}){
  const [inputLogin, setInputLogin] = useState({});
  const [inputPassword, setInputPassword] = useState({});
  const [disabled, setDisabled] = useState(false);

  const signIn = () => {
    let params = {
      data: {
        username: inputLogin,
        password: inputPassword,
      },
      type: 'post',
      path: TOKEN_START_URI + GET_TOKEN,
    }
    setDisabled(true)
    DoRequest(params)
      .then(response => {
        setDisabled(false);
        SetCookie('token', response.data.token, {'max-age': 60 * 60 * 8});
        setToken(response.data.token);
      }).catch(e => {
        setDisabled(false);
        let err = "Непредвиденная ошибка сервера"
        if (e.toString().indexOf("403") || e.toString().indexOf("401")) {
          err = "Неверное имя пользователя или пароль";
        }
        swal("Произошла ошибка",err, "error").then(e => {
          console.log(e.toString());
          DeleteCookie("token");
        });
      });
  }

  return (
    <>
      <Meta title="EFF_MEDIA | ROSTELECOM" />
      <aside className="login-form">
        <div className="card">
          <article className="card-body">
            <h4 className="card-title mb-4 mt-1 text-center">Войти</h4>
            <form>
              <div className={disabled? "disable-component" : ""}>
                <div className="form-group mb-4">
                  <label className="mb-2">Имя пользователя</label>
                  <input onChange={event => setInputLogin(event.target.value)}
                         className="form-control" placeholder="Имя пользователя" type="login" />
                </div>
                <div className="form-group mb-4">
                  <label className="mb-2">Пароль</label>
                  <input onChange={event => setInputPassword(event.target.value)}
                         className="form-control" placeholder="******" type="password" />
                </div>
              </div>
              <div className="form-group">
                <p onClick={() => signIn()} className="btn btn-primary btn-block">
                  Войти {disabled? <Preloader type="button" /> : <></>}
                </p>
              </div>
            </form>
          </article>
        </div>
      </aside>
    </>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
