import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import MainPostModalSaveField from "./MainPostModalSaveField";

const MainPost = ({post}) => {
  const [modalShow, setModalShow] = useState(false);
  const [fieldName, setFieldName] = useState();
  const [thisPost, setThisPost] = useState(post);
  let [fieldContent, setFieldContent] = useState();

  useEffect(() => {
    console.log(fieldContent)
  }, [fieldContent])

  const handleUpdateField = (event, fName) => {
    setFieldContent(event.target.textContent);
    setFieldName(fName);
    setModalShow(true);
    setThisPost({...thisPost, fName: fieldContent});
  }

  return (
    <>
      <Table responsive>
        <thead>
        <tr>
          <th className="table-col-max-width">Формат услуги</th>
          <th className="table-col-max-width">Площадка: Сайт</th>
          <th className="table-col-max-width">Ориентировочные темы</th>
          <th className="table-col-max-width">Ориентировочные даты</th>
          <th className="table-col-max-width">Планируемое кол-во размещений</th>
          <th className="table-col-max-width">Планируемое кол-во размещений</th>
          <th className="table-col-max-width">Коэффициент за услуги монтажа из исходников Заказчика (без съемки сюжета)</th>
          <th className="table-col-max-width">Коэффициент за выезд съемочной бригады в дополнительное место съемки</th>
          <th className="table-col-max-width">Коэффициент за съемку в выходной день</th>
          <th className="table-col-max-width">Коэффициент за позиционирование</th>
          <th className="table-col-max-width">Стоимость за ед. без учета НДС 20%</th>
          <th className="table-col-max-width">Сезонный коэффициент</th>
          <th className="table-col-max-width">Стоимость без НДС с коэф.</th>
          <th className="table-col-max-width">Планируемая стоимость с НДС и коэф.</th>
          <th className="table-col-max-width">Согласованная стоимость с НДС и коэф.</th>
          <th className="table-col-max-width">Примечания</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td onDoubleClick={event => handleUpdateField(event, "service_format")}>{post["service_format"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "site_website")}>{post["site_website"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "oriented_themes")}>{post["oriented_themes"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "oriented_dates")}>{post["oriented_dates"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "ponp")}>{post["ponp"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "anop")}>{post["anop"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "installation_service_rate")}>{post["installation_service_rate"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "film_crew_rate")}>{post["film_crew_rate"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "weekend_shooting_rate")}>{post["weekend_shooting_rate"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "cfp")}>{post["cfp"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "cost_without_irf")}>{post["cost_without_irf"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "season_coefficient")}>{post["season_coefficient"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "cost_without_nds_with_coefficients")}>{post["cost_without_nds_with_coefficients"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "planed_cost_without_nds_with_coefficients")}>{post["planed_cost_without_nds_with_coefficients"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "accepted_cost_without_nds_with_coefficients")}>{post["accepted_cost_without_nds_with_coefficients"]}</td>
          <td onDoubleClick={event => handleUpdateField(event, "service_format")}>{post["service_format"]}</td>
        </tr>
        <MainPostModalSaveField
          show={modalShow}
          onHide={() => setModalShow(false)}
          fieldName={fieldName}
          fieldContent={fieldContent}
          setFieldContent={setFieldContent}
        />
        </tbody>
      </Table>
    </>
  )
}

export default MainPost;
