import React, {useState} from "react";
import DeleteCookie from "../controller/cookie/DeleteCookie";
import ModalMainMenu from "./ModalMainMenu";
import {NavLink} from "react-bootstrap";

const MainMenu = ({setToken}) => {
  const [isModalShowed, setIsModalShowed] = useState(false);

  const handleToggleModal = (modalState) => {
    setIsModalShowed(modalState);
  }

  const handleLogout = () => {
    setToken("")
    DeleteCookie("token");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <p onClick={handleToggleModal} className="navbar-toggler">
          <span className="navbar-toggler-icon"/>
        </p>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link active" to="/">Главная</NavLink>
            </li>
          </ul>
        </div>
        <ul className="navbar-nav">
          <li className="container-fluid justify-content-end">
            <NavLink onClick={handleLogout.bind(this)} className="btn btn-sm btn-outline-secondary" to="/login">Выйти</NavLink>
          </li>
        </ul>
      </div>
      <ModalMainMenu showedState={isModalShowed} handlerToggleModal={handleToggleModal} />
    </nav>
  )
}

export default MainMenu;
