import React from "react";

const Preloader = ({ type }) => {

  const buttonType = () => {
    return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
  }

  const componentType = () => {
    return <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only" />
      </div>
    </div>
  }

  switch (type) {
    case "button":
      return buttonType();
    case "component":
      return componentType()
    default:
      return <></>
  }
}

export default Preloader;
