import React from "react";
import {Modal, Button} from "react-bootstrap";
import {Input} from "semantic-ui-react";

const MainPostModalSaveField = (props) => {
  let modalFieldContent = props.fieldContent
  const fieldName = props.fieldName;

  const setModalFieldContent = (c) => {
    modalFieldContent = c;
  }

  const saveFieldContent = () => {
    props.onHide()
    console.log(fieldName + ": " + modalFieldContent);
    props.setFieldContent(modalFieldContent);
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Редактировать поле</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Input onChange={event => setModalFieldContent(event.target.value)} defaultValue={modalFieldContent} fluid focus />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">Отмена</Button>
        <Button onClick={() => saveFieldContent()} variant="primary">Сохранить</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MainPostModalSaveField;
