import React, {useState} from "react";
import Filters from "../components/Filters";
import MainPosts from "../components/MainPosts";

const Home = () => {
  const [mainPosts, setMainPosts] = useState();

  return (
    <>
      <Filters setMainPosts={setMainPosts} />
      <MainPosts mainPosts={mainPosts} />
    </>
  );
}

export default Home;
