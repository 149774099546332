import React from "react";
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";

const ModalMainMenu = (props) => {

  const handleCloseModal = () => {
    props.handlerToggleModal(false);
  }

  return (
    <>
      <Modal show={props.showedState} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>EFF | RTK</Modal.Title>
          <button onClick={handleCloseModal} className="btn btn-secondary" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group-prepend">
            <Link to="/home" onClick={handleCloseModal} className="sidenav-link">Главная</Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalMainMenu;
