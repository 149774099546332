import React, {useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from "./Home";
import Login from "./Login";
import GetCookie from "../controller/cookie/GetCookie";
import MainMenu from "../components/MainMenu";

function MainPage() {
  const [token, setToken] = useState(GetCookie("token"));

  if (!token) {
    return <Login setToken={setToken} />
  } else {
    return (
      <>
        <div className="container">
          <header>
            <MainMenu setToken={setToken}/>
          </header>
          <div className="row">
            <Switch>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
          <footer>
            <></>
          </footer>
        </div>
      </>
    );
  }
}

export default MainPage;
